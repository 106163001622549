

import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, Link, Router} from 'react-router-dom';

import LogInGateway from "login/LogInGateway";
import SignIn from "login/SignIn";

import OnedropUser from "./OnedropUser";
import OnedropUserEmailHTML from "./OnedropUserEmailHTML";
import OnedropDisUser from "./OnedropDisUser";
import Emailsite from "./emailsite"



export default function Entry ({user}) {
    // eslint-disable-next-line no-restricted-globals
    const urlParams = new URL(location.href).searchParams;
    const code = urlParams.get('code');



    let url = "https://oauth2.googleapis.com/token";
    const headers = {

     };

    let accesstoken ="";
    useEffect(() => {

    },[]);


    return (
        <>
            <Route
                exact
                path="/"
                render={() => (
                    <>
                    <LogInGateway >
                        <SignIn />
                    </LogInGateway>
                    </>
                )

            }

            /> 
            <Route
                exact
                path="/signin"
                render={() => (
                    <>                                       
                    <SignIn/>                   
                    </>
                )}
            />
            <Route
                exact
                path="/distributor"
                render={() => (
                    <>
                        <LogInGateway user_type='distributor'>
                            {/*<DistributorUser />*/}
                            <OnedropDisUser />
                        </LogInGateway>
                    </>
                )}
            />

            <Route
                exact
                path="/site"
                render={() => (
                    <>
                        <LogInGateway user_type='site'>
                            {/*<SiteUser/>*/}
                            <OnedropUser accesstoken={accesstoken}/>
                        </LogInGateway>
                    </>
                )}
            />
            <Route
                exact
                path="/emailsite"
                render={() => (
                    <>
                        <LogInGateway user_type='site'>
                            {/*<SiteUser/>*/}
                            <Emailsite />
                        </LogInGateway>
                    </>
                )}
            />

            <Route
                exact
                path="/siteemail"
                render={() => (
                    <>
                        <LogInGateway user_type='site'>
                            {/*<SiteUser/>*/}
                            <OnedropUserEmailHTML/>
                        </LogInGateway>
                    </>
                )}
            />




        </>
    )
};

